/** Layout **/
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  main {
    flex: 1 0 auto;

    aside:first-child {
      order: 2;
    }

    &.container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 0 1rem;
    }

    &.container-reverse {
      @extend .container;
      flex-direction: row-reverse;
    }
  }
}
