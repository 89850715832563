html {
  font-family: $global-font-family;
  font-weight: $global-font-weight;
  font-display: swap;
  font-size: $global-font-size;
  line-height: $global-line-height;
  width: 100%;
  scroll-behavior: smooth;
  overflow: overlay;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/* scrollbar, only support webkit */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
  @include border-radius($global-border-radius);

  &:hover {
    background-color: $scrollbar-hover-color;
  }
}

::selection {
  background-color: $selection-color;

  [theme='dark'] & {
    background-color: $selection-color-dark;
  }
}

body {
  background-color: $global-background-color;
  color: $global-font-color;
  @include overflow-wrap(break-word);
  scrollbar-color: auto;

  &[theme='dark'] {
    color: $global-font-color-dark;
    background-color: $global-background-color-dark;
  }
}

img {
  @include object-fit(contain);
  @include border-radius($global-border-radius/2);
}

iframe {
  @include border-radius($global-border-radius);
}

@include ms;
@include link(true, true);

@import '../_partial/mask';
@import '../_partial/icon';
@import '../_partial/details';
@import '../_partial/fixed-button';
@import '../_partial/cookieconsent';
