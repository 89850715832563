/* 霞鹜文楷 */
@import url(https://cdn.jsdelivr.net/gh/satouriko/LxgwWenKai_Webfonts@v1.101/dist/LXGWWenKai-Regular.css);
body{
  font-family: LXGWWenKaiMono,system-ui,-apple-system,BlinkMacSystemFont,PingFang SC,Microsoft YaHei UI,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
}
article {
  color: #37352f;
  font-size: 18px;
  line-height: 1.6em;
  word-spacing: 2px;
  letter-spacing: 1px;
  li:nth-child(even) {
    margin: 6px 0;
  }
  li:last-child{
    margin-bottom: 0;
  }
}
body[theme='dark'] {
  article {
    color: #b1b1ba;
  }
}