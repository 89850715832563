#fixed-buttons {
  display: none;
}

.fixed-button {
  display: none;
  z-index: 100;
  position: fixed;
  right: 1.5rem;
  font-size: 1rem;
  line-height: 1.3rem;
  padding: 0.6rem 0.6rem;
  color: $global-font-secondary-color;
  background: $header-background-color;
  @include border-radius(2rem);
  @include transition(color 0.4s ease);

  @include blur;

  &:hover,
  &:active {
    color: $global-font-color;
    cursor: pointer;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  [theme='dark'] & {
    color: $global-font-secondary-color-dark;
    background: $header-background-color-dark;

    &:hover,
    &:active {
      color: $global-font-color-dark;
    }
  }
}

#back-to-top {
  display: block;
  bottom: 1.5rem;
}

#view-comments {
  bottom: 4.5rem;
}
